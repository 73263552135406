.App {
  text-align: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.App h1 {
  color: #333;
  margin-bottom: 40px;
}

.usersTable {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
}

.tableHeader {
  display: flex;
  background-color: #f5f5f5;
  padding: 15px;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
}

.tableRow {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.2s;
}

.tableRow:hover {
  background-color: #f5f5f5;
}

.tableRow:last-child {
  border-bottom: none;
}

.tableCell {
  flex: 1;
}

.headerCell {
  flex: 1;
}

.headerCell:first-child,
.tableCell:first-child {
  flex: 0 0 200px;
}

.headerCell:last-child,
.tableCell:last-child {
  flex: 1;
}

.userLink {
  color: #1976d2;
  text-decoration: none;
  font-weight: 500;
}

.userLink:hover {
  text-decoration: underline;
}

.error {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 1px solid #ef9a9a;
}

.updateInfo {
  color: #666;
  margin-bottom: 20px;
  font-size: 0.9em;
}

.polling {
  color: #2196f3;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.usersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.userCard {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.userCard:hover {
  transform: translateY(-5px);
}

.userCard h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.userCard p {
  margin: 0;
  color: #666;
}
