.user-detail {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.user-detail-content {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 800px;
    width: 100%;
    position: relative;
    z-index: 1;
}

.user-detail h1 {
    color: #333;
    font-size: 2.5em;
    margin-bottom: 20px;
}

.message {
    font-size: 1.5em;
    color: #666;
    margin-bottom: 30px;
}

.click-me-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 20px 0;
}

.click-me-button:hover {
    background-color: #45a049;
}

.special-message {
    font-size: 1.3em;
    color: #e91e63;
    margin-top: 20px;
    animation: fadeIn 0.5s ease-in;
}

.back-link {
    display: inline-block;
    margin-top: 30px;
    color: #666;
    text-decoration: none;
    font-size: 1.1em;
}

.back-link:hover {
    color: #333;
    text-decoration: underline;
}

.video-section {
    margin-top: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
}

.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    margin-bottom: 20px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
}

.video-title {
    color: #333;
    font-size: 1.2em;
    margin: 10px 0;
}

.balloons {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.balloon {
    position: absolute;
    width: 60px;
    height: 70px;
    animation: float 6s ease-in-out infinite;
}

.balloon:nth-child(1) { left: 10%; top: 20%; animation-delay: 0s; }
.balloon:nth-child(2) { left: 20%; top: 40%; animation-delay: 1s; }
.balloon:nth-child(3) { right: 20%; top: 30%; animation-delay: 2s; }
.balloon:nth-child(4) { right: 10%; top: 50%; animation-delay: 3s; }

.clown {
    position: absolute;
    width: 120px;
    height: 120px;
    bottom: 20px;
    right: 20px;
    animation: bounce 2s ease-in-out infinite;
    transform-origin: bottom center;
    pointer-events: none;
}

.clown-nose {
    animation: glow 1.5s ease-in-out infinite;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0) rotate(0deg);
    }
    50% {
        transform: translateY(-20px) rotate(5deg);
    }
}

@keyframes glow {
    0%, 100% {
        filter: brightness(1);
    }
    50% {
        filter: brightness(1.3);
    }
}

@keyframes float {
    0%, 100% {
        transform: translateY(0) rotate(3deg);
    }
    50% {
        transform: translateY(-20px) rotate(-3deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
